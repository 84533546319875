import { COUNTRY_CODE } from "constants/store";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "types";
import { getPathNameWithStoreType } from "utils/misc";

interface CountryAccessProps {
  children: React.ReactElement;
  pageProps: any;
}

const CountryAccess: FC<CountryAccessProps> = ({ children, pageProps }) => {
  const user = useSelector((state: ReduxState) => state?.user);
  const { userCountryPreference } = pageProps || {};

  // If no country access is provided, default to India (IN) only
  const defaultCountryAccess = [COUNTRY_CODE.india];
  const countryAccess = pageProps?.countryAccess || defaultCountryAccess;
  const isCountryAccess = countryAccess.includes(userCountryPreference?.code || defaultCountryAccess[0]) || countryAccess.includes(COUNTRY_CODE.all);

  useEffect(() => {
    // If Country details fetched from API and countryPreference cookie is not same as the selected country preference, then reload the page so cookie gets updated.
    if (user?.storesFetched && JSON.stringify(userCountryPreference) !== JSON.stringify(user?.selectedCountry)) {
      pageProps?.router.reload();
    }
  }, [user?.selectedCountry?.code, user?.selectedCountry?.id]);

  useEffect(() => {
    if (!isCountryAccess) {
      pageProps?.router.replace(getPathNameWithStoreType("/"));
    }
  }, [isCountryAccess]);

  if (!isCountryAccess) return <></>

  return <>{children}</>;
}

export default CountryAccess;
